<template>
  <PSection tag="div" pubg-reward-group-22s3>
    <div class="holder">
      <h2>대회 보상</h2>
      <PItem class="article-group">
        <article class="rank-1">
          <h3>
            <span>{{ rank1.title }}</span>
          </h3>
          <ul>
            <PItem tag="li" v-for="(item, i) in rank1.items" :key="`rank-1-${i}`">
              <div class="item">
                <figure>
                  <img :src="item.img" :alt="item.label">
                </figure>
                <p>{{ item.label }}</p>
              </div>
            </PItem>
          </ul>
        </article>
      </PItem>
      <PItem class="article-group">
        <article class="rank-3">
          <h3>
            <span>{{ rank2.title }}</span>
          </h3>
          <ul>
            <PItem tag="li" v-for="(item, i) in rank2.items" :key="`rank-3-${i}`">
              <div class="item">
                <figure>
                  <img :src="item.img" :alt="item.label">
                </figure>
                <p>{{ item.label }}</p>
              </div>
            </PItem>
          </ul>
        </article>
        <article class="rank-4">
          <h3>
            <span>{{ rank3.title }}</span>
          </h3>
          <ul>
            <PItem tag="li" v-for="(item, i) in rank3.items" :key="`rank-4-${i}`">
              <div class="item">
                <figure>
                  <img :src="item.img" :alt="item.label">
                </figure>
                <p>{{ item.label }}</p>
              </div>
            </PItem>
          </ul>
        </article>
      </PItem>
    </div>
    <div class="visual">
      <PItem alt="" v-prx:intro="{ r: [3, 8], ty:[300,0], o: [0, 1] }">
        <img src="/img/pages/pubg/intro/bg_reward_section_soldier.png" class="img" alt="">
      </PItem>
      <div class="video">
        <video ref="video" src="https://portal.lvup.gg/img/extra/riot/Sparks - 25164.mp4" playsinline autoplay loop muted></video>
      </div>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'PubgRewardGroup',
  data() {
    return {
      rank1: {
        title: '1위 보상',
        items: [
          { label: '300만원', img: '/img/pages/pubg/intro/img_reward_1.png' },
          { label: '차기 프로대회 시드권', img: '/img/pages/pubg/intro/img_reward_pro_league.png' },
          { label: '벤큐 2546k 모니터 (4대)', img: '/img/pages/pubg/intro/img_reward_5.png' },
        ],
      },
      rank2: {
        title: '2위 보상',
        items: [
          { label: '200만원', img: '/img/pages/pubg/intro/img_reward_2.png' },
          { label: '차기 프로대회 시드권', img: '/img/pages/pubg/intro/img_reward_pro_league.png' },
        ],
      },
      rank3: {
        title: '3위 보상',
        items: [
          { label: '100만원', img: '/img/pages/pubg/intro/img_reward_3.png' },
        ],
      },
    };
  },
  mounted() {
    this.$refs.video.playbackRate = 0.8;
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[pubg-reward-group-22s3] { .rel; .pt(211); .pb(40); .font-g; .c(#fff); .bgc(#131921); .no-repeat('@{pubg-intro}/bg_reward_section_clear.jpg'); .bg-s(1040, auto); .bg-xc;
  &:before { .cnt; .abs; .t(165); .l(0); .r(0); .b(600); .z(0); .bgc(rgba(0, 0, 0, .01)); animation: loop-backdrop 3s infinite; }
  .holder { .rel; .z(1); }
  h2, h3 { .max-w(335); .mh-c; .ls(-2); .tc; }
  h2 { .fs(28, 40); .mb(32); .no-repeat('@{pubg-intro}/bg_reward_section_title.svg'); .bg-xc; }
  .article-group { .max-w(335); .mh-c; .ani-base;
    .intro-up(1, { .ani-on; });
    + .article-group { .mt(24); }
  }
  article { .w(100%);
    h3 { .fs(24, 48); .tc; .bgc(rgba(118, 87, 147, .4)); .crop;
      span { .rel; .ib;
        &:before,
        &:after { .cnt; .abs; .wh(261, 26); .no-repeat; .bg-s(100%, 100%); }
        &:before { .mr(41); .rb(100%, 0); .bg('@{pubg-intro}/bg_reward_sub_tit_deco_l.svg'); }
        &:after { .ml(41); .lt(100%, 0); .bg('@{pubg-intro}/bg_reward_sub_tit_deco_r.svg'); }
      }
    }
    ul { .p(28, 0, 28, 0); .bgc(rgba(0, 0, 0, .3)); }
    li { .ani-base;
      .intro-up(1, { .ani-on; });
      + li { .mt(24); }
    }
    .item { .max-w(272); .mh-c; .tc;
      figure { .rel; .wh(100%, 170);
        &:before,
        &:after { .cnt; .abs; .wh(83, 32); .no-repeat; }
        &:before { .lt(0, 0); .bg('@{pubg-intro}/box_deco_l.svg'); }
        &:after { .rb(0, 0); .bg('@{pubg-intro}/box_deco_r.svg'); }
        img { .wh(100%, auto); .bgc(rgba(118, 87, 147, 0.2)); .-a(#765793); .-box; }
      }
      p { .mt(16); .medium; .fs(20); }
    }
    + article { .mt(24); }
  }
  .visual { .abs; .l(0); .r(0); .t(0); .b(80px); .crop; pointer-events: none;
    .img { .abs; .l(50%); .t(0); .t-xc_; .z(0); transform-origin: center top;
      transform+_: scale(.54);
      transition: transform .75s cubic-bezier(.4, 0, .2, 1);
    }
    .video { .abs; .l(0); .r(0); .t(0); .b(auto); mix-blend-mode: screen; .o(.25); .crop;
      video { .block; .wh(@screen-ds-min, auto); .mh-c; pointer-events: none; }
    }
  }
  @media (@tl-up) {
    .pt(302); .pb(46); .bg-s(1580, auto);
    &:before { .t(250); .b(0); }
    h2, h3 { .max-w(908);}
    h2 { .fs(40, 60); .mb(48); .bg('@{pubg-intro}/tl/bg_reward_section_title.svg'); }

    .article-group { .max-w(908); .flex; .flex-ai(flex-start);
      + .article-group { .mt(32); }
    }
    article {
      h3 { .fs(32, 68);
        span {
          &:before,
          &:after { .wh(452, 45); }
          &:before { .mr(35); }
          &:after { .ml(35); }
        }
      }
      ul { .p(40, 0, 40, 0); .flex; .flex-jc; }
      li {
        + li { .mt(0); .ml(12); }
      }
      .item p { .mt(20); .fs(24); }
      + article { .mt(0); .ml(16); }
      &.rank-4 { flex: none; .w(304); }
    }
    .visual {
      .img { .t-xc_; transform+_: scale(.82); }
      .video { .t(auto); .b(0); }
    }
  }
  @media (@ds-up) {
    .pt(400); .pb(80); .bg-s(auto, auto);
    &:before { .t(310); }
    h2, h3 { .max-w(@screen-ds-min);}
    h2 { .fs(48, 80); .bg('@{pubg-intro}/ds/bg_reward_section_title.svg'); }

    .article-group { .max-w(@screen-ds-min); }
    article {
      h3 { .fs(32, 68); }
      ul { .p(54, 0, 40, 0); }
      li {
        + li { .mt(0); .ml(32); }
      }
      .item { .max-w(372);
        figure { .h(232);
          &:before,
          &:after { .cnt; .abs; .wh(112, 44); .bg-s(112, 44); }
        }
        p { .mt(20); .fs(24); }
      }
      + article { .mt(0); .ml(16); }
      &.rank-4 { flex: none; .w(418); }
    }
    .visual {
      .img { .t-xc_; transform+_: scale(1); }
      .video video { .w(1920); }
    }
  }
}
</style>
